import moment from "moment-timezone";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";

import { PageWrapper } from "../components/app/PageWrapper";
import { Feeder, useQueryGetSiteById } from "../api";
import { FeederHealthStatus } from "../components/elements/FeederHealthStatus";
import { FeederCalibrationModal } from "../components/elements/FeederCalibrationModal";
import { LoadingComponent } from "../components/elements/LoadingComponent";
import { ErrorComponent } from "../components/elements/ErrorComponent";
import useQueryGetFeedersBySiteId from "../api/queries/GetFeedersBySiteId";

export const FeedersPage = () => {
  const params = useParams();
  const siteId = params.siteId;
  const navigate = useNavigate();
  const { loading, error, response } = useQueryGetSiteById(siteId);
  const {
    loading: loadingFeeders,
    error: errorFeeders,
    response: responseFeeders,
  } = useQueryGetFeedersBySiteId(siteId);

  const [showCalibrateModel, setShowCalibrateModal] = useState(false);
  const [selectedFeeder, setSelectedFeeder] = useState<Feeder>(null);

  if (loading || loadingFeeders) {
    return <LoadingComponent></LoadingComponent>;
  }

  if (error || !response.isSuccess) {
    return (
      <ErrorComponent error={error} message={response.message}></ErrorComponent>
    );
  }

  if (errorFeeders || !responseFeeders.isSuccess) {
    return (
      <ErrorComponent
        error={errorFeeders}
        message={responseFeeders.message}
      ></ErrorComponent>
    );
  }

  const feeders: any = responseFeeders.feeders.map((f) => {
    return {
      ...f,
      lastMessage: moment().toDate(),
      lastConnected: moment("06-01-2024", "DD-MM-YYYY").toDate(),
      isHealthy: true,
      calibration: {
        date: moment("07-01-2024", "DD-MM-YYYY").toDate(),
        tub: {
          tare: 23484238,
          scale: 439345,
        },
        platform: {
          tare: 23484238,
          scale: 439345,
        },
      },
      stats: {
        disconnects: 0,
        recordsCollected: 200,
        minLiveweight: 300000,
        maxLiveweight: 679993,
        meanLiveweight: 540002,
        missedTagReads: 10,
        feedRefilledWeight: 45000,
        totalFeedWeight: 394950,
        totalFeedTime: 3892,
        meanFeedTime: 437,
        meanFeedWeight: 3245,
        uniqueAnimals: 34,
      },
      status: {
        state: "IDLE",
        tub: {
          weight: 1020,
          status: "STABLE",
        },
        platform: {
          weight: 432,
          status: "TARED",
        },
        rfid: {
          lastTag: "950_423789432",
          MOF: 123,
          status: "OFF",
        },
        proximity: {
          distance: 804,
          status: "NO PROXIMITY",
        },
        record: {
          enterTime: moment().toDate(),
          tubAtEnter: 49560,
        },
      },
    };
  });

  const site = response.site;

  const onClickTrials = () => {
    navigate(`/sites/${siteId}/trials`);
  };

  return (
    <PageWrapper>
      <div className="mt-6 ml-4">
        {showCalibrateModel ? (
          <FeederCalibrationModal
            onCancel={() => setShowCalibrateModal(false)}
            onSuccess={() => setShowCalibrateModal(false)}
            feeder={selectedFeeder}
          />
        ) : (
          <></>
        )}

        <div className="flex">
          <div className="flex mr-4 mb-2">
            <h1 className="text-xl text-gray-900 dark:text-white">
              <Link className="cursor-pointer" to="/sites">
                Sites
              </Link>{" "}
              &gt;{" "}
              <Link className="cursor-pointer" to={`/sites/${site.id}`}>
                {site.name}
              </Link>{" "}
              &gt; Feeders
            </h1>
          </div>
        </div>
        <div className="flex">
          <div className="flex ml-auto mr-4">
            <span
              onClick={() => onClickTrials()}
              className="ml-2 text-sm font-medium text-blue-600 hover:text-blue-800 dark:text-blue-500  cursor-pointer"
            >
              Trials
            </span>
          </div>
        </div>
        <div className="mt-6 p-4 bg-white rounded-lg border shadow-md sm:p-8 dark:bg-gray-800 dark:border-gray-700">
          <div className="flex mb-4">
            <div className="flex"></div>
            <div className="flex ml-auto"></div>
          </div>

          {feeders.length ? (
            <div className="flow-root">
              <ul className="divide-y divide-gray-200 dark:divide-gray-700">
                {feeders.map((feeder: any) => (
                  <li className="py-3 sm:py-4" key={feeder.id}>
                    <div className="flex space-x-4">
                      <div className="flex-1 min-w-0">
                        <p className="text-md font-bold text-gray-900 truncate dark:text-white font-semibold">
                          {feeder.name} [{feeder.controllerId}]
                        </p>
                        <p className="text-sm text-gray-500 dark:text-gray-400">
                          Last message: {moment(feeder.lastMessage).fromNow()}
                        </p>
                        <p className="text-sm text-gray-500 dark:text-gray-400">
                          Calibrated:{" "}
                          {moment(feeder.calibration.date).format("D MMM YYYY")}
                        </p>
                      </div>
                      <div className="ml-auto flex-col items-right text-base font-semibold">
                        <div className="text-gray-900 dark:text-white">
                          {feeder.status.state}
                        </div>
                        <div>
                          <FeederHealthStatus feeder={feeder} />
                        </div>
                      </div>
                    </div>
                    <div className="mt-2 flex-1 min-w-0 text-gray-900 truncate dark:text-white">
                      <button
                        type="submit"
                        tabIndex={1}
                        onClick={() => {
                          setSelectedFeeder(feeder);
                          setShowCalibrateModal(true);
                        }}
                        className="mt-4 px-3 py-2 text-sm text-white bg-orange-500 rounded-md focus:bg-orange-300 focus:outline-none disabled:opacity-50 dark:bg-orange-500"
                      >
                        CALIBRATE
                      </button>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            <div className="text-gray-900 dark:text-white">
              No feeders found
            </div>
          )}
        </div>
      </div>
    </PageWrapper>
  );
};

export default FeedersPage;
