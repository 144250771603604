/**
 * A query to get a list of trials.
 */

import { gql, useQuery } from "@apollo/client";
import { Trial } from "../interfaces";

export const GET_TRIAL_BY_ID = gql`
  query GetTrialById($id: ID!) {
    siteTrialById(id: $id) {
      isSuccess
      message
      trial {
        id
        startDate
        endDate
        site {
          id
          name
        }
        pens {
          pen {
            id
            name
          }
          records {
            id
            enterTime
            animalTag
            feedWeight
            feedTime
            animalWeight
            isValid
          }
        }
      }
    }
  }
`;

interface GetTrialsInput {
  id: string;
}

export interface GetTrialsResponse {
  siteTrialById: {
    isSuccess: boolean;
    message: string;
    trial: Trial;
  };
}

export const useQueryGetTrialById = (id: string) => {
  let trial: Trial = null;
  const { loading, data, error } = useQuery<GetTrialsResponse, GetTrialsInput>(
    GET_TRIAL_BY_ID,
    { variables: { id } }
  );

  if (!loading && !error && data && data.siteTrialById.isSuccess) {
    trial = data.siteTrialById.trial;
  }

  return {
    loading,
    error,
    response: {
      isSuccess: data ? data.siteTrialById.isSuccess : false,
      message: data ? data.siteTrialById.message : "",
      trial,
    },
  };
};

export default useQueryGetTrialById;
