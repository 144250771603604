/**
 * A query to get a list of trials.
 */

import { gql, useQuery } from "@apollo/client";
import { AnimalDailyData } from "../interfaces";

export const GET_TRIAL_RECORDS = gql`
  query GetTrialById($trialId: ID!) {
    trialDailyRecords(input: { trialId: $trialId }) {
      isSuccess
      message
      records {
        id
        date
        animalTag
        feedWeight
        feedTime
        animalWeight
        animalWeightGrowth
        visits
        pen {
          name
        }
      }
    }
  }
`;

interface GetTrialRecordsInput {
  trialId: string;
}

export interface GetTrialRecordsResponse {
  trialDailyRecords: {
    isSuccess: boolean;
    message: string;
    records: AnimalDailyData[];
  };
}

export const useQueryGetTrialRecords = (trialId: string) => {
  const { loading, data, error } = useQuery<
    GetTrialRecordsResponse,
    GetTrialRecordsInput
  >(GET_TRIAL_RECORDS, { variables: { trialId } });

  return {
    loading,
    error,
    response: {
      isSuccess: data ? data.trialDailyRecords.isSuccess : false,
      message: data ? data.trialDailyRecords.message : "",
      records: data ? data.trialDailyRecords.records : [],
    },
  };
};

export default useQueryGetTrialRecords;
