/**
 * A query to get a list of feeders.
 */

import { gql, useQuery } from "@apollo/client";
import { Feeder } from "../interfaces";

export const GET_FEEDERS = gql`
  query GetFeedersBySiteId($siteId: ID!) {
    feedersBySiteId(input: { id: $siteId }) {
      isSuccess
      message
      feeders {
        id
        name
        controllerId
        site {
          id
          name
        }
      }
    }
  }
`;

interface GetFeedersBySiteIdInput {}

export interface GetFeedersBySiteIdResponse {
  feedersBySiteId: {
    isSuccess: boolean;
    message: string;
    feeders: Feeder[];
    meta: any;
  };
}

export const useQueryGetFeedersBySiteId = (siteId: string) => {
  let feeders: Feeder[] = [];
  const { loading, data, error } = useQuery<
    GetFeedersBySiteIdResponse,
    GetFeedersBySiteIdInput
  >(GET_FEEDERS, { variables: { siteId } });

  if (!loading && !error && data && data.feedersBySiteId.isSuccess) {
    feeders = data.feedersBySiteId.feeders;
  }

  return {
    loading,
    error,
    response: {
      isSuccess: data ? data.feedersBySiteId.isSuccess : false,
      message: data ? data.feedersBySiteId.message : "",
      feeders,
    },
  };
};

export default useQueryGetFeedersBySiteId;
