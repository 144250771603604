import { Feeder } from "../../api/interfaces";

interface FeederHealthStatusProps {
  feeder: Feeder;
}

export const FeederHealthStatus = ({ feeder }: FeederHealthStatusProps) => {
  if (feeder.isHealthy) {
    return (
      <div className="inline-flex items-center text-base font-semibold text-green-500 dark:text-green-500">
        Healthy
      </div>
    );
  }

  return (
    <div className="inline-flex items-center text-base font-semibold text-red-500 dark:text-red-500">
      Unhealthy
    </div>
  );
};

export default FeederHealthStatus;
