import { useEffect, useState } from "react";
import { Modal } from "./Modal";
import { useToasts } from "../../hooks/useToasts";
import useFeederStartCalibrationMutation from "../../api/mutations/FeederStartCalibration";
import { Feeder } from "../../api";
import { SuccessAlert } from "../app/SuccessAlert";
import { ErrorAlert } from "../app/ErrorAlert";

const CALIBRATION_TIMEOUT = 30;

enum CalibrationState {
  Init,
  Wait1,
  Complete,
  TimedOut,
}

interface FeederCalibrationModalProps {
  onCancel(): void;
  onSuccess(): void;
  feeder: Feeder;
}

export const FeederCalibrationModal = ({
  onCancel,
  onSuccess,
  feeder,
}: FeederCalibrationModalProps) => {
  const [calibrationState, setCalibrationState] = useState(
    CalibrationState.Init
  );
  const { mutate: feederStartCalibrationMutation } =
    useFeederStartCalibrationMutation();

  const [startCountdown, setStartCountdown] = useState(false);
  const [countdownExpired, setCountdownExpired] = useState(false);
  const [countdown, setCountdown] = useState(0);
  const { newToast } = useToasts();

  useEffect(() => {
    let id: NodeJS.Timeout;
    if (startCountdown) {
      id = setInterval(() => {
        setCountdown((count) => {
          if (id && count === 0) {
            setCountdownExpired(true);
            clearInterval(id);
            return 0;
          }
          return count - 1;
        });
      }, 1000);
      setStartCountdown(false);
    }
  }, [startCountdown]);

  useEffect(() => {
    if (countdownExpired) {
      newToast(<ErrorAlert message="Calibration timed out" />);
      setCalibrationState(CalibrationState.TimedOut);
      setCountdownExpired(false);
    }
  }, [countdownExpired, newToast]);

  const sendStartCalibration = async (feeder: Feeder) => {
    try {
      const { data } = await feederStartCalibrationMutation({
        variables: {
          siteId: feeder.site.id,
          feederId: feeder.id,
        },
      });
      if (data.siteStartFeederCalibration.isSuccess) {
        newToast(<SuccessAlert message="Start calibration command sent" />);
        setCalibrationState(CalibrationState.Wait1);
        setCountdown(CALIBRATION_TIMEOUT);
        setStartCountdown(true);
      } else {
        newToast(
          <ErrorAlert message={`${data.siteStartFeederCalibration.message}`} />
        );
      }
      // FIXME:
    } catch (err) {
      newToast(<ErrorAlert message={`${err}`} />);
    }
  };

  return (
    <Modal
      title={`Calibration: ${feeder.site.name} - ${feeder.name}`}
      onClose={() => onCancel()}
    >
      <div>
        {calibrationState === CalibrationState.Init ? (
          <div>
            <div className="text-red-500">
              <h1 className="font-bold text-xl">WARNING</h1>
              Ensure the tub and platform are clean and empty before clicking
              start.
            </div>
            <button
              onClick={() => sendStartCalibration(feeder as any)}
              className="mt-8 w-full mt-4 px-3 py-4 text-white bg-green-800 rounded-md focus:bg-gray-600 focus:outline-none disabled:opacity-50"
            >
              Send Start Message
            </button>
          </div>
        ) : (
          ""
        )}
        {calibrationState === CalibrationState.Wait1 ? (
          <div>
            <div className="text-gray-900 dark:text-white">
              Waiting device...{countdown}
            </div>
          </div>
        ) : (
          ""
        )}
        {calibrationState === CalibrationState.Complete ? (
          <div>
            <div className="text-gray-900 dark:text-white">Finished.</div>
          </div>
        ) : (
          ""
        )}
        {calibrationState === CalibrationState.TimedOut ? (
          <div>
            <div className="text-red-500">
              <h1 className="font-bold text-xl">FAILED</h1>
              Calibration timed out or device communication was lost.
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </Modal>
  );
};
