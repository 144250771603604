// import { DataFrame } from "dataframe-js";
import { uniq, mean, sum } from "lodash";
import { AnimalDailyData } from "../api/interfaces";
import { GrowthDataSeries } from "../components/charts/GrowthChart";
import { IntakeDataSeries } from "../components/charts/IntakeChart";

export const calculateSummaryData = (data: AnimalDailyData[]) => {
  // const pens = uniq(data.map((d) => d.pen));
  const dates = uniq(data.map((d) => d.date.getTime()))
    .sort()
    .map((d) => new Date(d));
  const tags = uniq(data.map((d) => d.animalTag));
  const days = dates.length;
  const totalVisits = sum(data.map((d) => d.visits));
  const totalConsumed = sum(data.map((d) => d.feedWeight));
  const consumedByAnimal = totalConsumed / tags.length;
  const consumedByAnimalByDay = consumedByAnimal / days;
  // const startAverageLiveweight = mean(
  //   data
  //     .filter((d) => d.date.getTime() === dates[0].getTime())
  //     .map((d) => d.liveweight)
  // );
  const endAverageLiveweight = mean(
    data
      .filter((d) => d.date.getTime() === dates[dates.length - 1].getTime())
      .map((d) => d.animalWeight)
  );
  const growth = Math.floor(
    mean(
      dates.map((dt) => {
        const dailyGrowth = data
          .filter((d) => d.date.getTime() === dt.getTime())
          .map((d) => d.animalWeightGrowth);
        return mean(dailyGrowth);
      })
    )
  );
  console.log(growth);

  return {
    days,
    animals: tags.length,
    liveweight: endAverageLiveweight,
    visits: totalVisits,
    visitsPerDay: parseInt((totalVisits / dates.length).toFixed(0), 10),
    feedConsumed: totalConsumed,
    feedConsumedPerDay: consumedByAnimalByDay,
    feedConsumedPerDayPerAnimal: consumedByAnimalByDay,
    growthPerDayPerAnimal: growth,
    tags,
  };
};

export const calculateIntakeData = (
  data: AnimalDailyData[]
): IntakeDataSeries => {
  const intakeData: IntakeDataSeries = {};
  const pens = uniq(data.map((d) => d.pen.name));
  const dates = uniq(data.map((d) => d.date.getTime())).map((d) => new Date(d));
  pens.forEach((pen) => {
    intakeData[pen] = [];
    dates.forEach((date) => {
      intakeData[pen].push({
        date,
        intake:
          mean(
            data
              .filter(
                (d) => d.date.getTime() === date.getTime() && d.pen.name === pen
              )
              .map((d) => d.feedWeight)
          ) / 1000,
      });
    });
  });
  return intakeData;
};

export const calculateGrowthData = (
  data: AnimalDailyData[]
): GrowthDataSeries => {
  const growthData: GrowthDataSeries = {};
  const pens = uniq(data.map((d) => d.pen.name));
  const dates = uniq(data.map((d) => d.date.getTime())).map((d) => new Date(d));
  pens.forEach((pen) => {
    growthData[pen] = [];
    dates.forEach((date) => {
      growthData[pen].push({
        date,
        liveweight:
          mean(
            data
              .filter(
                (d) => d.date.getTime() === date.getTime() && d.pen.name === pen
              )
              .map((d) => d.animalWeight)
          ) / 1000,
        growth:
          mean(
            data
              .filter(
                (d) => d.date.getTime() === date.getTime() && d.pen.name === pen
              )
              .map((d) => d.animalWeightGrowth)
          ) / 1000,
      });
    });
  });
  return growthData;
};
