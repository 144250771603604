import { Trial } from "../../api/interfaces";
import moment from "moment-timezone";
import { Modal } from "./Modal";
import { useQueryGetTrialById } from "../../api";
import { useToasts } from "../../hooks/useToasts";
import { SuccessAlert } from "../app/SuccessAlert";
import { useState } from "react";
import RecordsDownloader from "./RecordsDownloader";
import DailyRecordsDownloader from "./DailyRecordsDownloader";

interface TrialDownloadModalProps {
  onClose(): void;
  trialId: string;
}

export const TrialDownloadModal = ({
  onClose,
  trialId,
}: TrialDownloadModalProps) => {
  const { loading, error, response } = useQueryGetTrialById(trialId);
  const [downloadRawRecords, setDownloadRawRecords] = useState(false);
  const [downloadRecords, setDownloadRecords] = useState(false);
  const { newToast } = useToasts();
  const [downloadError, setDownloadError] = useState("");

  if (loading) return <>Loading</>;
  if (error) return <>Error</>;

  const trial = response.trial;

  console.log(trial);

  // useEffect(() => {
  //   if (
  //     responseDailyRecords?.trialDailyRecords &&
  //     responseDailyRecords?.trialDailyRecords.isSuccess
  //   ) {
  //     const data = responseDailyRecords.trialDailyRecords.records.map(
  //       (d: AnimalDailyData) => {
  //         return {
  //           ...d,
  //           date: moment(d.date).format("YYYY-MM-DD"),
  //         };
  //       }
  //     );
  //     const filename = `${trial.site.name.replace(" ", "-")}-${moment(
  //       trial.startDate
  //     ).format("YYYYMMDD")}-${moment(trial.endDate).format("YYYYMMDD")}.csv`;
  //     downloadCSV(data, DAILY_RECORDS_FIELDS, filename);
  //     // newToast(<SuccessAlert message={`Downloaded ${filename}`} />);
  //   }
  // });

  //   if (
  //     downloadRaw &&
  //     responseRecords?.trialRecords &&
  //     responseRecords?.trialRecords.isSuccess
  //   ) {
  //     const data = responseRecords.trialRecords.records.map((d: FeedRecord) => {
  //       return {
  //         ...d,
  //         date: moment(d.date).format("YYYY-MM-DD"),
  //         exitTime: moment(d.exitTime).format("YYYY-MM-DD hh:mm:ss"),
  //         enterTime: moment(d.enterTime).format("YYYY-MM-DD hh:mm:ss"),
  //         pen: d.pen.name,
  //         feeder: d.feeder.name,
  //       };
  //     });
  //     const filename = `${trial.site.name.replace(" ", "-")}-${moment(
  //       trial.startDate
  //     ).format("YYYYMMDD")}-${moment(trial.endDate).format(
  //       "YYYYMMDD"
  //     )}-raw.csv`;
  //     downloadCSV(data, RECORDS_FIELDS, filename);
  //     // newToast(<SuccessAlert message={`Downloaded ${filename}`} />);
  //     setDownloadRaw
  //     onClose();
  //   }
  // });

  // // FIXME:
  // if (loading || error) {
  //   return <></>;
  // }

  // const trial = response.trial;

  const title = (trial: Trial) => {
    return `Trial ${moment(trial.startDate).format("DD-MMM-YYYY")} to
    ${moment(trial.endDate).format("DD-MMM-YYYY")}`;
  };

  const onClickDownloadAnimalData = () => {
    setDownloadRecords(true);
  };

  const onClickDownloadData = () => {
    setDownloadRawRecords(true);
    // getRecords({ variables: { trialId } });
  };

  return (
    <Modal title="Download Data" onClose={onClose}>
      {downloadError ? <div className="text-red-500">{downloadError}</div> : ""}
      {downloadRawRecords || downloadRecords ? (
        downloadRawRecords ? (
          <RecordsDownloader
            trial={trial}
            onError={(msg) => {
              setDownloadRawRecords(false);
              setDownloadError(msg);
            }}
            onDone={() => {
              setDownloadRecords(false);
              newToast(
                <SuccessAlert message="Trial raw data download started" />
              );
              onClose();
            }}
          />
        ) : (
          <DailyRecordsDownloader
            trial={trial}
            onError={(msg) => {
              setDownloadRawRecords(false);
              setDownloadError(msg);
            }}
            onDone={() => {
              setDownloadRecords(false);
              newToast(<SuccessAlert message="Trial data download started" />);
              onClose();
            }}
          />
        )
      ) : (
        <div>
          <div className="text-gray-900 dark:text-white">
            Download data for the {trial.site.name} {title(trial)}.
          </div>
          <div className="mx-6 mt-4 flex gap-2 flex-col">
            <div>
              <button
                onClick={() => onClickDownloadAnimalData()}
                className="ml-2 text-sm font-medium text-blue-600 hover:text-blue-800 dark:text-blue-500  cursor-pointer"
              >
                Animal Daily Records
              </button>
            </div>
            <div>
              <button
                onClick={() => onClickDownloadData()}
                className="ml-2 text-sm font-medium text-blue-600 hover:text-blue-800 dark:text-blue-500  cursor-pointer"
              >
                Raw Records
              </button>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default TrialDownloadModal;
