import { useNavigate } from "react-router-dom";
import { useQueryGetSites, Site } from "../api";
import { PageWrapper } from "../components/app/PageWrapper";
import { useEffect } from "react";
import { ErrorComponent } from "../components/elements/ErrorComponent";
import { LoadingComponent } from "../components/elements/LoadingComponent";

export const SitesPage = () => {
  const navigate = useNavigate();
  const { loading, error, response } = useQueryGetSites();

  useEffect(() => {
    if (response.sites.length === 1) {
      navigate(`/sites/${response.sites[0].id}`);
    }
  }, [response.sites, navigate]);

  const sites = response.sites;

  const onClickSite = (site: Site) => {
    navigate(`/sites/${site.id}`);
  };

  if (loading) {
    return <LoadingComponent></LoadingComponent>;
  }

  if (error || !response.isSuccess) {
    return (
      <ErrorComponent error={error} message={response.message}></ErrorComponent>
    );
  }

  return (
    <PageWrapper>
      <div className="mt-6 ml-4">
        <div className="flex">
          <div className="flex mr-4 mb-2">
            <h1 className="text-xl text-gray-900 dark:text-white">Sites</h1>
          </div>
        </div>
        <div className="mt-6 p-4 bg-white rounded-lg border shadow-md sm:p-8 dark:bg-gray-800 dark:border-gray-700">
          <div className="flex mb-4">
            <div className="flex">
              {/* <h3 className="text-xl font-bold leading-none text-gray-900 dark:text-white">
                Sites
              </h3> */}
            </div>
            <div className="flex ml-auto"></div>
          </div>
          {sites.length ? (
            <div className="flow-root">
              <ul className="divide-y divide-gray-200 dark:divide-gray-700">
                {sites.map((site: any) => (
                  <li className="py-3 sm:py-4" key={site.id}>
                    <div className="flex items-center space-x-4">
                      <div
                        onClick={() => onClickSite(site)}
                        className="flex-1 min-w-0 cursor-pointer"
                      >
                        <p className="text-md font-bold text-gray-900 truncate dark:text-white font-semibold">
                          {site.name}
                        </p>
                      </div>
                      <div className="inline-flex items-center text-base font-semibold text-green-500 dark:text-green-500"></div>
                    </div>
                    <div className="mt-2 flex-1 min-w-0 text-gray-900 truncate dark:text-white"></div>
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            <div className="text-gray-900 dark:text-white">No sites found</div>
          )}
        </div>
      </div>
    </PageWrapper>
  );
};

export default SitesPage;
