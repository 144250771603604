/**
 * A query to get a list of sites.
 */

import { gql, useQuery } from "@apollo/client";
import { Site } from "../interfaces";

export const GET_SITES = gql`
  query GetSites {
    sites {
      isSuccess
      message
      sites {
        id
        name
        pens {
          id
          name
        }
      }
    }
  }
`;

interface GetSitesInput {}

export interface GetSitesResponse {
  sites: {
    isSuccess: boolean;
    message: string;
    sites: Site[];
    meta: any;
  };
}

export const useQueryGetSites = () => {
  let sites: Site[] = [];
  const { loading, data, error } = useQuery<GetSitesResponse, GetSitesInput>(
    GET_SITES,
    {}
  );

  if (!loading && !error && data && data.sites.isSuccess) {
    sites = data.sites.sites;
  }

  return {
    loading,
    error,
    response: {
      isSuccess: data ? data.sites.isSuccess : false,
      message: data ? data.sites.message : "",
      sites,
    },
  };
};

export default useQueryGetSites;
