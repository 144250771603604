import moment from "moment-timezone";
import { Trial } from "../../api/interfaces";

interface TrialStatusProps {
  trial: Trial;
}

export const TrialStatus = (props: TrialStatusProps) => {
  const trial = props.trial;
  if (
    moment().isSameOrAfter(trial.startDate, "day") &&
    moment().isSameOrBefore(trial.endDate, "day")
  ) {
    return (
      <div className="inline-flex items-center text-base font-semibold text-green-500 dark:text-green-500">
        In Progress
      </div>
    );
  }
  if (moment().isBefore(trial.startDate, "day")) {
    return (
      <div className="inline-flex items-center text-base font-semibold text-pink-500 dark:text-pink-500">
        Scheduled
      </div>
    );
  }
  if (moment().isAfter(trial.endDate, "day")) {
    return (
      <div className="inline-flex items-center text-base font-semibold text-orange-500 dark:text-orange-500">
        Ended
      </div>
    );
  }
};

export default TrialStatus;
