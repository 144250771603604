/**
 * The user request Account page.
 */

import { Modal } from "./Modal";

interface AlertsModalProps {
  onSuccess(): void;
  onCancel(): void;
  alerts: any[];
}

export const AlertsModal = ({
  onSuccess,
  onCancel,
  alerts,
}: AlertsModalProps) => {
  return (
    <Modal title="Alerts" onClose={onCancel}>
      <div className="text-gray-900 dark:text-white">
        The following issues have been detected:
      </div>
      {alerts.map((alert) => (
        <div className="ml-4 text-red-500">{alert.message}</div>
      ))}
    </Modal>
  );
};

export default AlertsModal;
