import moment from "moment-timezone";
import { downloadCSV } from "../../modules/data-downloads";
import { FeedRecord, GET_TRIAL_RAW_RECORDS, Trial } from "../../api";
import { useEffect } from "react";
import { useLazyQuery } from "@apollo/client";

const RECORDS_FIELDS = [
  "date",
  "animalTag",
  "pen",
  "feeder",
  "enterTime",
  "exitTime",
  "feedWeight",
  "animalWeight",
  "feedTime",
  "feedRate",
];

interface DailyRecordsDownloaderProps {
  onDone(): void;
  onError(msg: string): void;
  trial: Trial;
}

export const DailyRecordsDownloader = ({
  onDone,
  onError,
  trial,
}: DailyRecordsDownloaderProps) => {
  const [getRecords, { error, data: response }] = useLazyQuery(
    GET_TRIAL_RAW_RECORDS
  );

  const loadRecords = () => {
    getRecords({ variables: { trialId: trial.id } });
  };

  // Load once at start
  useEffect(() => {
    console.log("[Downloader] Loading");
    loadRecords();
    return;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (error) {
      console.error(`[Downloader] Error ${error.message}`);
      if (error.message.length > 200) {
        onError(error.message.slice(0, 200) + "...");
      } else {
        onError(error.message);
      }
    }
  }, [error, onError]);

  const loaded = response?.trialRecords;

  useEffect(() => {
    if (loaded) {
      console.log("[Downloader] Loaded");
      if (!response.trialRecords.isSuccess) {
        onError(response.trialRecords.message);
        return;
      }
      const data = response?.trialRecords.records.map((d: FeedRecord) => {
        return {
          ...d,
          date: moment(d.date).format("YYYY-MM-DD"),
          exitTime: moment(d.date).format("YYYY-MM-DD hh:mm:ss"),
          enterTime: moment(d.date).format("YYYY-MM-DD  hh:mm:ss"),
          pen: d.pen.name,
          feeder: d.feeder.name,
        };
      });
      const filename = `${trial.site.name.replace(" ", "-")}-${moment(
        trial.startDate
      ).format("YYYYMMDD")}-${moment(trial.endDate).format(
        "YYYYMMDD"
      )}-raw.csv`;
      downloadCSV(data, RECORDS_FIELDS, filename);
      onDone();
    }
  }, [response, loaded, trial, onDone, onError]);

  return <div className="text-gray-900 dark:text-white">Loading...</div>;
};

export default DailyRecordsDownloader;
