import { useState } from "react";
import moment from "moment-timezone";
import { Link, useNavigate, useParams } from "react-router-dom";

import PageWrapper from "../components/app/PageWrapper";
import TrialDownloadModal from "../components/elements/TrialDownloadModal";
import { DeviceIcon, AddIcon } from "../components/icons";
import { useQueryGetSiteById } from "../api";
import TrialStatus from "../components/elements/TrialStatus";
import TrialAddModal from "../components/elements/TrialAddModal";
import { Site } from "../api";
import { LoadingComponent } from "../components/elements/LoadingComponent";
import { ErrorComponent } from "../components/elements/ErrorComponent";
import { useToasts } from "../hooks/useToasts";
import { SuccessAlert } from "../components/app/SuccessAlert";

export const TrialsPage = () => {
  const params = useParams();
  const siteId = params.siteId;
  const { newToast } = useToasts();

  const { loading, error, response } = useQueryGetSiteById(siteId);

  const [showTrialDownloadModal, setShowTrialDownloadModal] = useState(false);
  const [selectedTrial, setSelectedTrial] = useState(null);
  const [showAddTrialModal, setShowAddTrialModal] = useState(false);

  const navigate = useNavigate();

  const onClickViewDashboard = (trial: any) => {
    navigate(`/sites/${siteId}/trials/${trial.id}/dashboard`);
  };

  const onClickManageFeeders = () => {
    navigate(`/sites/${siteId}/feeders`);
  };

  const onClickDownload = (trial: any) => {
    setSelectedTrial(trial);
    setShowTrialDownloadModal(true);
  };

  const onCancelAddTrial = () => {
    setShowAddTrialModal(false);
  };

  const onClickAddTrial = () => {
    setShowAddTrialModal(true);
  };

  const isSiteAdmin = (site: Site) => {
    return site.roles.find((r: any) => r.name === "site-admin");
  };

  if (loading) {
    return <LoadingComponent></LoadingComponent>;
  }

  if (error || !response.isSuccess) {
    return (
      <ErrorComponent error={error} message={response.message}></ErrorComponent>
    );
  }

  const site = response.site;

  console.log(site.trials);

  return (
    <PageWrapper>
      <div className="mt-1 ml-4">
        {showTrialDownloadModal ? (
          <TrialDownloadModal
            onClose={() => {
              setShowTrialDownloadModal(false);
            }}
            trialId={selectedTrial.id}
          ></TrialDownloadModal>
        ) : (
          ""
        )}
        {showAddTrialModal ? (
          <TrialAddModal
            onCancel={onCancelAddTrial}
            onSuccess={(trial) => {
              setShowAddTrialModal(false);
              newToast(
                <SuccessAlert message={`Added new trial ${trial.id}`} />,
                5000
              );
            }}
          ></TrialAddModal>
        ) : (
          ""
        )}

        <div className="flex">
          <div className="flex mr-4 mb-2">
            <h1 className="text-xl text-gray-900 dark:text-white">
              <Link className="cursor-pointer" to="/sites">
                Sites
              </Link>{" "}
              &gt;{" "}
              <Link className="cursor-pointer" to={`/sites/${site.id}`}>
                {site.name}
              </Link>{" "}
              &gt; Trials
            </h1>
          </div>
        </div>
        <div className="flex">
          <div className="flex ml-auto mr-4">
            {isSiteAdmin(site) ? (
              <span
                onClick={() => onClickManageFeeders()}
                className="ml-2 text-sm font-medium text-blue-600 hover:text-blue-800 dark:text-blue-500  cursor-pointer"
              >
                <DeviceIcon></DeviceIcon> Manage Feeders
              </span>
            ) : (
              ""
            )}

            {isSiteAdmin(site) ? (
              <span
                onClick={() => onClickAddTrial()}
                className="ml-2 text-sm font-medium text-blue-600 hover:text-blue-800 dark:text-blue-500 cursor-pointer"
              >
                <AddIcon></AddIcon> Add Trial
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="mt-6 p-4 bg-white rounded-lg border shadow-md sm:p-8 dark:bg-gray-800 dark:border-gray-700">
          <div className="flex mb-4">
            <div className="flex">
              {/* <h3 className="text-xl font-bold leading-none text-gray-900 dark:text-white">
                Trials
              </h3> */}
            </div>
            <div className="flex ml-auto"></div>
          </div>
          {site.trials.length ? (
            <div className="flow-root">
              <ul className="divide-y divide-gray-200 dark:divide-gray-700">
                {site.trials.map((trial: any) => (
                  <li className="py-3 sm:py-4 mt-5" key={trial.id}>
                    <div className="flex items-center space-x-4">
                      <div className="flex-1 min-w-0">
                        <p className="text-md font-bold text-gray-900 truncate dark:text-white font-semibold">
                          {moment(trial.startDate).format("DD MMM YYYY")} to{" "}
                          {moment(trial.endDate).format("DD MMM YYYY")}
                        </p>
                        <p className="text-sm text-gray-500 dark:text-gray-400">
                          {trial.pens.map((p: any) => p.pen.name).join(", ")}
                        </p>
                      </div>
                      <div className="inline-flex items-center text-base font-semibold text-green-500 dark:text-green-500">
                        <TrialStatus trial={trial} />
                      </div>
                    </div>
                    <div className="mt-2 flex-1 min-w-0 text-gray-900 truncate dark:text-white">
                      <span
                        className="text-sm font-medium text-blue-600 hover:text-blue-800 dark:text-blue-500  cursor-pointer"
                        onClick={() => onClickDownload(trial)}
                      >
                        Download Data
                      </span>
                      <span
                        className="ml-4 text-sm font-medium text-blue-600 hover:text-blue-800 dark:text-blue-500  cursor-pointer"
                        onClick={() => onClickViewDashboard(trial)}
                      >
                        Dashboard
                      </span>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            <div className="text-gray-900 dark:text-white">No trials found</div>
          )}
        </div>
      </div>
    </PageWrapper>
  );
};

export default TrialsPage;
