import { Route, Routes, Navigate } from "react-router-dom";
import { ProtectedRoute } from "./components/app/ProtectedRoute";
import { LoginPage } from "./pages/LoginPage";
import { TrialDashboardPage } from "./pages/TrialDashboard";
import { TrialsPage } from "./pages/TrialsPage";
import { UserPasswordResetPage } from "./pages/ForgottenPasswordPage";
import { UserRequestAccountPage } from "./pages/UserRequestAccount";
import { SitePage } from "./pages/SitePage";
import { SitesPage } from "./pages/SitesPage";
import { FeedersPage } from "./pages/FeedersPage";
import TrialPage from "./pages/TrialPage";
import { useTheme } from "./hooks/useTheme";
import { PasswordResetPage } from "./pages/PasswordResetPage";

function App() {
  const { theme } = useTheme();

  return (
    <div className={`${theme === "dark" ? "dark" : ""} `}>
      <div
        className={`${
          theme === "dark" ? "dark" : ""
        } bg-white dark:bg-gray-800 flex grow flex-col h-full w-full min-h-screen `}
      >
        <Routes>
          <Route path="/" element={<Navigate to="/sites/" />}></Route>

          <Route
            path="/trials"
            element={
              <ProtectedRoute>
                <TrialsPage></TrialsPage>
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="/trials/:trialId/dashboard"
            element={
              <ProtectedRoute>
                <TrialDashboardPage></TrialDashboardPage>
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="/sites"
            element={
              <ProtectedRoute>
                <SitesPage></SitesPage>
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="/sites/:siteId"
            element={
              <ProtectedRoute>
                <SitePage></SitePage>
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="/sites/:siteId/trials"
            element={
              <ProtectedRoute>
                <TrialsPage></TrialsPage>
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="/sites/:siteId/feeders"
            element={
              <ProtectedRoute>
                <FeedersPage></FeedersPage>
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="/sites/:siteId/trials/:trialId"
            element={
              <ProtectedRoute>
                <TrialPage></TrialPage>
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="/sites/:siteId/trials/:trialId/dashboard"
            element={
              <ProtectedRoute>
                <TrialDashboardPage></TrialDashboardPage>
              </ProtectedRoute>
            }
          ></Route>

          <Route
            path="/reset-password"
            element={
              <ProtectedRoute>
                <PasswordResetPage></PasswordResetPage>
              </ProtectedRoute>
            }
          ></Route>

          <Route
            path="/login"
            element={
              <LoginPage
                showForgotPasswordLink={true}
                showSignUpLink={true}
              ></LoginPage>
            }
          ></Route>
          <Route
            path="/forgotten-password"
            element={<UserPasswordResetPage></UserPasswordResetPage>}
          ></Route>
          <Route
            path="/sign-up"
            element={<UserRequestAccountPage></UserRequestAccountPage>}
          ></Route>
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
