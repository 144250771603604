import { useState } from "react";
import { AlertsModal } from "./AlertsModal";
import moment from "moment-timezone";

export const Alerts = () => {
  const [showAlertsModal, setShowAlertsModal] = useState(false);

  const alerts = [
    {
      message: `UnE Sheep Feeders: Feeder 2 has not sent a message since ${moment(
        "01-01-2024",
        "DD-MM-YYYY"
      ).format("DD MMM YYYY")}`,
    },
  ];

  if (alerts.length === 0) {
    return <></>;
  }

  return (
    <div>
      {showAlertsModal ? (
        <AlertsModal
          onCancel={() => setShowAlertsModal(false)}
          onSuccess={() => setShowAlertsModal(false)}
          alerts={alerts}
        />
      ) : (
        ""
      )}
      <button
        type="button"
        onClick={() => setShowAlertsModal(true)}
        className="mr-2 inline-flex items-center px-5 py-2 text-sm font-medium text-center text-white bg-red-500 rounded-md hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
      >
        Alerts
        <span className="inline-flex items-center justify-center w-4 h-4 ms-2 text-xs font-semibold text-red-800 bg-red-200 rounded-full">
          {alerts.length}
        </span>
      </button>
    </div>
  );
};
