/**
 * A query to get a list of trials.
 */

import { gql, useQuery } from "@apollo/client";
import { FeedRecord } from "../interfaces";

export const GET_TRIAL_RAW_RECORDS = gql`
  query GetTrialRawRecords($trialId: ID!) {
    trialRecords(input: { trialId: $trialId }) {
      isSuccess
      message
      records {
        id
        date
        enterTime
        exitTime
        animalTag
        feedWeight
        feedTime
        feedRate
        animalWeight
        pen {
          name
        }
        feeder {
          name
        }
      }
    }
  }
`;

interface GetTrialRawRecordsInput {
  trialId: string;
}

export interface GetTrialRawRecordsResponse {
  trialRecords: {
    isSuccess: boolean;
    message: string;
    records: FeedRecord[];
  };
}

export const useQueryGetTrialRawRecords = (trialId: string) => {
  const { loading, data, error } = useQuery<
    GetTrialRawRecordsResponse,
    GetTrialRawRecordsInput
  >(GET_TRIAL_RAW_RECORDS, { variables: { trialId } });

  return {
    loading,
    error,
    response: {
      isSuccess: data ? data.trialRecords.isSuccess : false,
      message: data ? data.trialRecords.message : "",
      records: data ? data.trialRecords.records : [],
    },
  };
};

export default useQueryGetTrialRawRecords;
